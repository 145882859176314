import http from '@/utils/http';

/**
 * 分类管理
 */
export default {
  getList: {
    p: 'post,/manage/industry/getlist',
    r: (data) => http({ url: '/manage/industry/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/industry/add',
    r: (data) => http({ url: '/manage/industry/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/industry/update',
    r: (data) => http({ url: '/manage/industry/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/industry/delete',
    r: (data) => http({ url: '/manage/industry/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/industry/getone',
    r: (data) => http({ url: '/manage/industry/getone', method: 'post', data })
  }
};
